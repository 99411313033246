import { env } from '../../constants'

export const KEEP_ALIVE_ENDPOINT = env.pick({
  local: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-cit2/keepAlive',
  dev: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-cit2/keepAlive',
  qa1: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat1/keepAlive',
  qa2: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat2/keepAlive',
  qa3: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat2/keepAlive',
  prod: '/acs/v1/autoapi/event/keepAlive',
})

export const KEEP_ALIVE_INTERVAL = (15 * 60 - 30) * 1000 // 30 seconds before 15 minutes

export const LOGIN_ENDPOINT = env.pick({
  local: '/acs/v1/autoapi-aaos-auto-api-misc-100294-cit2/login',
  dev: '/acs/v1/autoapi-aaos-auto-api-misc-100294-cit2/login',
  qa1: '/acs/v1/autoapi-aaos-auto-api-misc-100294-uat1/login',
  qa2: '/acs/v1/autoapi-aaos-auto-api-misc-100294-uat2/login',
  qa3: '/acs/v1/autoapi-aaos-auto-api-misc-100294-uat2/login',
  prod: '/acs/v1/autoapi/login',
})

export const LOGOUT_ENDPOINT = env.pick({
  local: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-cit2/logout',
  dev: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-cit2/logout',
  qa1: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat1/logout',
  qa2: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat2/logout',
  qa3: '/acs/v1/autoapi/event-aaos-auto-api-event-100294-uat2/logout',
  prod: '/acs/v1/autoapi/event/logout',
})

export const SHARED_API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  clientAppName: 'AllyUSOLA', // Hard Coded to desktop
}
